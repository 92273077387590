import styled from 'styled-components';

import { media } from '~/src/styles-constants';

export const RefreshCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 40px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;

    span:first-child {
      margin-right: 8px;
    }

    span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.palette.common.BLACK};
      ${media.S`
         font-size: 16px;
         `}
    }
  }
`;
