import React from 'react';

import { RichContent } from '~/src/common/components/CMS/RichText/layout';

interface Props {
  html?: string;
  className?: string;
}

const RichText = ({ html, ...props }: Props) => {
  return html != null ? (
    <RichContent dangerouslySetInnerHTML={{ __html: html }} {...props} />
  ) : null;
};

export default RichText;
