import { BusinessErrors } from '@keplr/api-catalog-errors';

import Dialog from '~/src/common/components/Dialog';
import I18n from '~/src/common/services/I18n';

const { Order } = BusinessErrors;
/**
 * Erreur E_08_0004 - Le panier est vide
 * Le paiement de la commande a échoué car le panier ne contient pas d'article
 * Comportement:
 * Un message d'erreur est affiché et la commande n'est pas finalisée (prepay bloqué)
 */

export default {
  code: Order.EmptyCart.code,
  handler: () => {
    Dialog.error({
      title: I18n.t('errors.default-title'),
      description: I18n.t('errors.E_08_0004'),
    });
  },
};
