import { BusinessErrors } from '@keplr/api-catalog-errors';

import invalidTimeSlotHandler from './E_08_0001';

const { Order } = BusinessErrors;
/**
 * Erreur E_08_0003 - Votre panier est invalide
 * Apparaît quand on veut valider la commande avec un créneau inconnu
 * Comportement : On invite l'utilisateur à sélectionner un nouveau créneau
 */

export default {
  code: Order.InvalidCart.code,
  handler: invalidTimeSlotHandler.handler,
};
