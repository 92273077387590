import { BusinessErrors } from '@keplr/api-catalog-errors';

const { PIM } = BusinessErrors;
/**
 * Erreur E_04_00_0003 - Le produit n'existe plus
 * Le produit n'existe plus dans le catalogue produit
 * Comportement: L'utilisateur est redirigé vers la homepage
 */

export default {
  code: PIM.Article.ArticleNotFound.code,
  handler: () => {
    window.location.assign('/');
  },
};
