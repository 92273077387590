import { QueryClient } from '@tanstack/react-query';

import { ModalState } from '~/src/common/services/ModalsManager';
import { ApiEcomError } from '~/src/queries/services/client';

import Unauthorized from './auth/E_01_0000';
import ResetPasswordTokenExpired from './auth/E_01_0001';
import InvalidCoupon from './coupon/E_13_0005';
import InvalidCustomerId from './customers/E_02_0000';
import TimeSlotExcluded from './delivery/E_ECOM_12_0002';
import ProductNotFound from './marketing/E_06_0001';
import InvalidTimeSlot from './orders/E_08_0001';
import InvalidCart from './orders/E_08_0003';
import EmptyCart from './orders/E_08_0004';
import CartNotFound from './orders/E_08_0005';
import DeliverySlotDeleted from './orders/E_08_0011';
import ProductDoesNotExistAnymore from './pim/E_04_00_0003';
import InvalidPromoCode from './promo-code/E_21_0003';
import InvalidReferral from './referral/E_CAT_00_0004';

export interface HandlerPayload {
  error: ApiEcomError;
  store?: {
    queryClient: QueryClient;
    modalsState?: ModalState;
  };
}

type BusinessErrorsHandlers = {
  [errorCode: string]: (payload: HandlerPayload) => void;
};

const businessErrorsHandlers: BusinessErrorsHandlers = {
  // Auth
  [Unauthorized.code]: Unauthorized.handler,
  [ResetPasswordTokenExpired.code]: ResetPasswordTokenExpired.handler,

  // Orders
  // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
  [InvalidTimeSlot.code]: InvalidTimeSlot.handler,
  // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
  [InvalidCart.code]: InvalidCart.handler,
  // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
  [CartNotFound.code]: CartNotFound.handler,
  // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
  [DeliverySlotDeleted.code]: DeliverySlotDeleted.handler,
  [EmptyCart.code]: EmptyCart.handler,

  // Marketing
  [ProductNotFound.code]: ProductNotFound.handler,

  // PIM
  [ProductDoesNotExistAnymore.code]: ProductDoesNotExistAnymore.handler,

  // Customer
  [InvalidCustomerId.code]: InvalidCustomerId.handler,

  // Coupons
  [InvalidCoupon.code]: InvalidCoupon.handler,

  // Promocodes
  [InvalidPromoCode.code]: InvalidPromoCode.handler,

  // Referral
  [InvalidReferral.code]: InvalidReferral.handler,

  // Delivery
  // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
  [TimeSlotExcluded.code]: TimeSlotExcluded.handler,
};

export default businessErrorsHandlers;
