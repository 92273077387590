import { BusinessErrors } from '@keplr/api-catalog-errors';

import invalidTimeSlotHandler from './E_08_0001';

const { Order } = BusinessErrors;
/**
 * Erreur E_08_0011 - Le créneau de livraison n'est plus valide
 * Le créneau de livraison n'est plus valide et ne peut donc plus être utilisé (supprimé/modifié/expiré)
 * Comportement: On invite l'utilisation sélectionner une adresse de livraison (si besoin) et un nouveau créneau
 * et on met à jour le panier si des données concernant ce dernier on été renvoyées dans la réponse
 */

export default {
  code: Order.DeliverySlotDeleted.code,
  handler: invalidTimeSlotHandler.handler,
};
