import { PropsWithChildren } from 'react';

import { ReadMoreContent, SeeMoreButton } from '~/src/common/components/ReadMore/layout';
import useReadMore from '~/src/common/components/ReadMore/useReadMore';
import I18n from '~/src/common/services/I18n/I18n';
import Tracker from '~/src/common/services/Tracker';

interface ReadMoreProps extends PropsWithChildren {
  path: string;
  containerHeight?: number;
}

export const ReadMore = ({ containerHeight = 117, path, children }: ReadMoreProps) => {
  const { ref, toggle, collapsed } = useReadMore(containerHeight);

  const onClick = () => {
    toggle();
    Tracker.sendEvent(collapsed ? 'read more' : 'read less', {
      'current page path': path,
    });
  };

  return (
    <>
      <ReadMoreContent
        ref={ref}
        style={{
          height: collapsed ? containerHeight : ref.current?.scrollHeight ?? 'auto',
          transition: 'height 750ms',
        }}>
        {children}
      </ReadMoreContent>
      {collapsed != null ? (
        <SeeMoreButton type="button" onClick={onClick}>
          {collapsed ? I18n.t('read-more.see-more') : I18n.t('read-more.see-less')}{' '}
        </SeeMoreButton>
      ) : null}
    </>
  );
};
