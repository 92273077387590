import { BusinessErrors } from '@keplr/api-catalog-errors';

import InvalidTimeSlot from './E_08_0001';

const { Order } = BusinessErrors;
/**
 * Erreur E_08_0005 - Le panier est introuvable
 * Apparaît quand l'utilisation a passé commande sur un autre onglet
 * Comportement : On affiche un message et invite l'utilisateur à rafraichir le site
 */

export default {
  code: Order.CartNotFound.code,
  handler: InvalidTimeSlot.handler,
};
