import { styled } from 'styled-components';

export const ReadMoreContent = styled.div`
  overflow: hidden;
  position: relative;
`;

export const SeeMoreButton = styled.button`
  width: 100%;
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
  padding: 0;
`;
