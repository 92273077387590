import styled from 'styled-components';

import {
  H1styles,
  H2styles,
  H3styles,
  H4styles,
  Astyles,
  TextStyles,
} from '~/src/common/components/Typography';

export const RichContent = styled.div`
  h1 {
    ${H1styles}
    ${({ theme }) => `
    margin-bottom: ${theme.typography.h1.marginBottom};
    a {
      font-size: ${theme.typography.h1.fontSize};
    }
  `};
  }

  h2 {
    ${H2styles}
    ${({ theme }) => `
    margin-bottom: ${theme.typography.h2.marginBottom};
    a {
      font-size: ${theme.typography.h2.fontSize};
    }
  `};
  }

  h3 {
    ${H3styles}
    ${({ theme }) => `
    margin-bottom: ${theme.typography.h3.marginBottom};
    a {
      font-size: ${theme.typography.h3.fontSize};
    }
  `};
  }

  h4 {
    ${H4styles}
  }

  a {
    ${Astyles}
  }

  p {
    margin-top: 0;
    ${TextStyles};
    ${({ theme }) => `
    margin-bottom: ${theme.typography.p.marginBottom};
  `};
  }

  strong {
    font-weight: 500;
    line-height: 24px;
  }

  ul,
  ol {
    padding: 0;
    list-style-position: inside;
    line-height: 24px;
    ${TextStyles};
  }

  ul {
    ${({ theme }) => `
    margin-bottom: ${theme.typography.p.marginBottom};
  `};
  }
`;
