import { QueryClient } from '@tanstack/react-query';

import Tracker from '~/src/common/services/Tracker';
import { QUERY_KEYS } from '~/src/screens/App/queries/queries';

export const handleSignoutSuccess = (queryClient: QueryClient) => {
  queryClient.setQueryData(QUERY_KEYS.user, {});

  Tracker.sendEvent('logged out');

  window.location.assign('/');
};
