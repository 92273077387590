import React from 'react';

import styled from 'styled-components';

const ReasonList = styled.ul`
  list-style: none;
  text-align: center;
`;

const UnavailableCouponReasonsList = ({ reasons }: { reasons: string[] }) => (
  <ReasonList>
    {reasons.map(reason => (
      <li key={reason}>{reason}</li>
    ))}
  </ReasonList>
);

export default UnavailableCouponReasonsList;
