import { BusinessErrors } from '@keplr/api-catalog-errors';

import Dialog from '~/src/common/components/Dialog';
import I18n from '~/src/common/services/I18n';

const { Customers } = BusinessErrors;
/**
 * Erreur E_02_0000 - L'email fourni comme identifiant client n'existe pas
 * Apparaît quand l'email utilisé dans la requête de reset de password ne correspond à aucun client
 * Comportement : On affiche un message à l'utilisateur spécifique indiquant un problème au niveau du token
 */

export default {
  code: Customers.NotFound.code,
  handler: () => {
    Dialog.error({
      title: I18n.t('errors.default-title'),
      description: I18n.t('reset-password-modal.invalid-email-address'),
    });
  },
};
