import Button from '~/src/common/components/Button';
import Icon from '~/src/common/components/Icon/Icon';
import I18n from '~/src/common/services/I18n/I18n';

import * as S from './layout';

type Props = {
  onButtonClick: () => void;
};

const RefreshCategory = ({ onButtonClick }: Props) => (
  <S.RefreshCategoryContainer>
    <p>
      <Icon name="warning-normal" size={24} role="img" color="PRIMARY" />
      <span>{I18n.t('common.refresh-category.error-label')}</span>
    </p>
    <Button type="button" onClick={onButtonClick}>
      {I18n.t('common.refresh-category.error-button')}
    </Button>
  </S.RefreshCategoryContainer>
);

export default RefreshCategory;
