import { v4 as uuidv4 } from 'uuid';

import * as PageS from '../layout';

import * as S from './layout';

type Props = {
  isHome?: boolean;
};

const SubCategoryContentSkeleton = () => {
  const nbProductCards = new Array(8).fill('');
  return (
    <>
      <S.TextLineMedium />
      <S.TextLineSmall width="80%" />
      <S.SkeletonProductCardGrid>
        {nbProductCards.map(_ => (
          <PageS.ProductPlaceholder key={uuidv4()} />
        ))}
      </S.SkeletonProductCardGrid>
    </>
  );
};

const CategoryContentSkeleton = ({ isHome = false }: Props) => {
  const divArray = new Array(8).fill('').map((_, i) => <div key={i} />);

  return (
    <S.CategorySkeletonWrapper>
      {!isHome ? (
        <S.SkeletonMobileMenu>
          <S.SkeletonCategoryMobileMenu>{divArray}</S.SkeletonCategoryMobileMenu>
          <S.SkeletonSubCategoryMobileMenu>{divArray}</S.SkeletonSubCategoryMobileMenu>
        </S.SkeletonMobileMenu>
      ) : null}
      <S.CategoryTreeViewContainer>
        <S.TextLineBig />
        <S.TextLineSmall width="30%" />
        <S.TextLineSmall />
        <S.TextLineSmall width="50%" />
        <S.TextLineSmall />
        <S.TextLineSmall width="25%" />
      </S.CategoryTreeViewContainer>

      <S.SkeletonSubCategory>
        <SubCategoryContentSkeleton />
        <SubCategoryContentSkeleton />
      </S.SkeletonSubCategory>
    </S.CategorySkeletonWrapper>
  );
};

export default CategoryContentSkeleton;
