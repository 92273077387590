import { BusinessErrors } from '@keplr/api-catalog-errors';

const { Marketing } = BusinessErrors;
/**
 * Erreur E_06_0001 - Aucun produit pertinent trouvé en rapport avec l'article {X}
 * Aucun produit avec cet id n'a pu être identifié dans le catalogue produit
 * Comportement: L'utilisateur est redirigé vers la homepage
 */

export default {
  code: Marketing.Catalogues.ActiveCatalogueNotFound.code,
  handler: () => {
    window.location.assign('/');
  },
};
